<template>
  <div id="extra-component-chartist-demo">
    <div class="vx-row">
      <b-row>
        <b-col
          v-if="false"
          cols="2"
        >
          <b-card  class="h-100">
            <!-- <b-button
              class="mb-2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              block
            >
              Create Reports
            </b-button> -->

            <h4 class="mb-1 font-weight-bold filter-heading d-none d-lg-block">
              Filters
            </h4>
            <hr>

            <span> Reports </span>
            <div class="pl-1 py-1">
              <b-form-group
                label="From"
                label-for="date_filter_from"
              >
                <b-form-input
                  id="date_filter_from"
                  v-model="filter.from"
                  type="date"
                />
              </b-form-group>
              <b-form-group
                label="To"
                label-for="date_filter_to"
              >
                <b-form-input
                  id="date_filter_to"
                  v-model="filter.to"
                  type="date"
                />
              </b-form-group>
            </div>

            <b-button
              size="sm"
              variant="primary mt-1 w-100"
              block
            >Apply Filter</b-button>

            <h6 class="my-1 font-weight-bold filter-heading d-none d-lg-block">
              Sort by
            </h6>
            <hr>

            <b-button
              size="sm"
              pill
              :variant="`${ sortBy == 'date' ? '' : 'outline-' }primary mb-1`"
              @click="sortBy = 'date'"
            >Date</b-button>

            <b-button
              size="sm"
              pill
              :variant="`${ sortBy == 'name' ? '' : 'outline-' }primary mb-1`"
              @click="sortBy = 'name'"
            >Name</b-button>

            <b-button
              size="sm"
              :variant="`${ sortBy == 'popular' ? '' : 'outline-' }primary mb-1`"
              pill
              @click="sortBy = 'popular'"
            >Popular</b-button>
          </b-card>
        </b-col>
        <b-col>
          <b-overlay :show="isLoading">
            <platform-analytics-table />-
          </b-overlay>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'

import PlatformAnalyticsChartGrid from './PlatformAnalyticsChartGrid.vue'
import PlatformAnalyticsTable from './PlatformAnalyticsTable.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BOverlay,

    PlatformAnalyticsChartGrid,
    PlatformAnalyticsTable,
  },
  data() {
    return {
      // sidebar
      sortBy: null,
      filter: {
        from: '',
        to: '',
      },

      // chart
      jobs: {},
      users: {},
      applicants: {},
      employment: {},
      qualification: {},
      gender: {},
      citizenship: {},
      themeColors: [
        '#7367F0',
        '#28C76F',
        '#EA5455',
        '#FF9F43',
        'dark',
        '#1E1E1E',

        '#F59DAC',
        '#26E615',
        '#1195B6',
        '#C10545',
        '#EDC6AA',
        '#4485DA',
        '#7B8057',
        '#789425',
        '#254EA5',
        '#FC7745',
        '#B419B9',
        '#19146A',
      ],
      isLoading: false,
    }
  },
  created() {
    // this.get_data()
  },
  methods: {
    chart_option_generator(chart_type, title, x_data, colors) {
      let chartOptions = {}
      if (chart_type === 'line') {
        chartOptions = {
          chart: {
            background: 'white',
            height: 350,
            zoom: {
              autoScaleYaxis: true,
              enabled: true,
            },
          },
          colors,
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: title,
            align: 'left',
          },
          grid: {
            row: {
              colors: ['transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            type: 'datetime',
            categories: x_data,
          },
        }
      } else if (chart_type == 'pie') {
        chartOptions = {
          labels: x_data,
          colors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        }
      } else {
        chartOptions = {
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          colors,
          xaxis: {
            type: 'datetime',
            categories: x_data,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        }
      }

      return chartOptions
    },
    get_data() {
      this.isLoading = true
      const { token } = this.$store.state.auth.ActiveUser
      const company = this.$store.state.auth.ActiveUser.company_name

      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.$http
        .get(`/api/analytics/${company}`)
        .then(response => {
          const output = response.data.data

          this.isLoading = false
        })
        .catch(error => {
          // console.log('get_date()', error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.analytics-grid {
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
