var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"h-100 w-100"},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-card-title',[_vm._v("Reports")]),_c('div',{staticStyle:{"min-width":"50vw","min-height":"20vw","width":"100%","height":"100%"}},[_c('vue-good-table',{attrs:{"mode":"remote","pagination-options":{
          enabled: true,
          perPageDropdown: [10, 20, 50, 100],
          dropdownAllowAll: false,
        },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"is-loading":_vm.isLoading,"total-rows":_vm.totalRecords,"rows":_vm.reportData,"columns":_vm.columnDefs,"style-class":"vgt-table condensed bordered","compact-mode":""},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.onSelectionChange},scopedSlots:_vm._u([{key:"column-filter",fn:function(props){return [(props.column.field === 'updated_on')?_c('span',{staticClass:"mt-2"},[_c('flat-pickr',{staticClass:"vgt-input",attrs:{"config":_vm.configPicker,"placeholder":"Filter Date Modified"},on:{"input":function (value) { return _vm.updateFilters(props.column.field, value); }},model:{value:(_vm.updated_on_filter),callback:function ($$v) {_vm.updated_on_filter=$$v},expression:"updated_on_filter"}})],1):(props.column.field === 'created_on')?_c('span',{staticClass:"mt-2"},[_c('flat-pickr',{staticClass:"vgt-input",attrs:{"config":_vm.configPicker,"placeholder":"Filter Added On"},on:{"input":function (value) { return _vm.updateFilters(props.column.field, value); }},model:{value:(_vm.created_on_filter),callback:function ($$v) {_vm.created_on_filter=$$v},expression:"created_on_filter"}})],1):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'report_name')?_c('span',[_c('CellRendererLink',{attrs:{"params":{
                user_id: _vm.$store.state.auth.ActiveUser.id,
                id: props.row.id,
                value: props.row.report_name,
                data: props.row,
              }}})],1):(props.column.field === 'actions')?_c('span',[_c('CellRendererActions',{attrs:{"params":{
                user_id: _vm.$store.state.auth.ActiveUser.id,
                id: props.row.id,
                data: props.row,
              }}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }